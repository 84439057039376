import React from "react";
import "../css/about2.css";
import Approach from "./approach";
import Nav from "./nav";
import Footer from "./footer";
import GetInTouch from "./getintouch";

import ScrollamaDemo from "./scrollytelling";

export default function About() {
  return (
    <div className="about">
      <Nav />
      <p className="about-desc">
        We Are <span>Thinkers,</span><br/>
        We build <span>brands for tomorrow.</span>
      </p>
      <Approach />
      <ScrollamaDemo />
      {/* <h1 className="team-head">Meet our Frontliners</h1>
      <div className="team">
        <div className="himanshu">
          <img className="team-img" src={himanshu} />
          <div className="team-desc">
            <div>
              <p className="name">
                Himanshu <span>Khemani</span>{" "}
              </p>
              <p className="exp">Co-founder || Exp. : +6 Years</p>
              <p className="desc">
                Lorem ipsum dolor sit amet consectetur. Arcu sagittis massa odio
                malesuada vitae.
              </p>
            </div>

            <span className="links">
              <a href="https://www.linkedin.com/in/himanshukhemani/">
                LinkedIn
              </a>
            </span>
          </div>
        </div>
        <div className="harshit">
          <img className="team-img" src={harshit} />
          <div className="team-desc">
            <div>
              <p className="name">
                Harshit <span>Sharma</span>
              </p>
              <p className="exp">Co-founder || Exp. : +5 Years</p>
              <p className="desc">
                Lorem ipsum dolor sit amet consectetur. Arcu sagittis massa odio
                malesuada vitae.
              </p>
            </div>

            <span className="links">
              <a href="https://www.linkedin.com/in/harshitsharma34/">
                LinkedIn
              </a>
            </span>
          </div>
        </div>
      </div> */}
      <GetInTouch />
      <Footer />
    </div>
  );
}
