import React from "react";
import "../css/footer.css";
import footerImg from "../Assets/footerImg.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div className="social-links">
        <div className="social">
          <div class="line-1"></div>
          <p>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.linkedin.com/company/miniaturestudio"
              className="linkedin"
              target="_blank"
            >
              {" "}
              <span className="arrow">↗</span> LinkedIn
            </a>
          </p>
          <div class="line-2"></div>
        </div>
        <div className="social">
          <p>
            <a
              style={{ textDecoration: "none" }}
              href="https://dribbble.com/MiniatureStudio"
              className="dribbble"
              target="_blank"
            >
              {" "}
              <span className="arrow">↗</span> Dribbble
            </a>
          </p>
          <div class="line-2"></div>
        </div>
        <div className="social">
          <p>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.instagram.com/theminiature.club/"
              className="insta"
              target="_blank"
            >
              <span className="arrow">↗</span> Instagram
            </a>
          </p>
          <div class="line-2"></div>
        </div>
        <div className="social">
          <p>
            <a
              style={{ textDecoration: "none" }}
              href="https://twitter.com/miniature_club"
              className="twitter"
              target="_blank"
            >
              <span className="arrow">↗</span> Twitter
            </a>
          </p>
          <div class="line-2"></div>
        </div>
        <div className="social">
          <p>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.behance.net/miniaturestudio"
              className="behance"
              target="_blank"
            >
              <span className="arrow">↗</span> Behance
            </a>
          </p>
          <div class="line-2"></div>
        </div>
      </div>
      <div className="email-div">
        <p> YOUR WORDPRESS THEME IS...BORING? NO WORRIES. LET'S TALK</p>

        <a className="people-miniature" href="mailto:people@theminiature.co">
          people@
          <br />
          theminiature.co
        </a>
        <p> Crafted for every product maker out there over a cup of ☕</p>
      </div>
     
    </div>
  );
}
