import React from "react";
import "../css/home.css";
import { Link } from "react-router-dom";
import scroll from "../Assets/scroll.svg";
import LogoText from "../Assets/text_logo.svg";
import Pattern from "../Assets/pattern.svg";
import Nav from "./nav";
import bg from "../Assets/miniature_bg.png";

function Home() {
  return (
    <div>
      <div className="hero">
        <Nav />
        <span className="bg">
          {" "}
          <img src={bg}/>
        </span>

        <iframe
          class="animation"
          src="https://my.spline.design/bganimation-bd0d9128c19fadcb38478ed08cab2375/"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>

        <div className="head-text-div">
          <p className="ai-pd">AI and Product Design Agency /// India</p>
          <h1>
            UX first Product Design Agency for{" "}
            <span className="b2b">B2B and AI Companies</span>
          </h1>
          <div className="mvp">
            <p>We launch</p>
            <span className="pills">MVPs</span>
            <p>redefine</p>
            <span className="pills">Existing Products </span>
            <p>& continuously dive into </p>
            <span className="pills">Customer Development</span>
          </div>
        </div>
      </div>
      <section class="o-section c-adv snipcss-Jdq7A">
        <div class="o-container c-adv__pane">
          <div class="c-v2adv__item">
            <div class="c-adv__nmb">/ 01</div>
            <div class="c-adv__label">$300M+</div>
            <div class="o-nhg24 c-adv__txt">
              in funding secured <br />
              for our clients
            </div>
          </div>
          <div class="c-v2adv__item">
            <div class="c-adv__nmb">/ 02</div>
            <div class="c-adv__label">2019</div>
            <div class="o-nhg24 c-adv__txt">founded 5+ years of experience</div>
            <div aspect="309/402" class="c-adv__img w-embed">
              <div class="u-await-holder t-bg-800"></div>
            </div>
          </div>
          <div class="c-v2adv__item">
            <div class="c-adv__nmb">/ 03</div>
            <div class="c-adv__label">2015</div>
            <div class="o-nhg24 c-adv__txt">Founded, 9 years of experience</div>
          </div>
          <div class="c-v2adv__item">
            <div class="c-adv__nmb u-gutter">/ 04</div>
            <div class="c-adv__label u-gutter">Bangaluru</div>
            <div class="o-nhg24 c-adv__txt">
              based in the heart of Indian Silicon Valley
            </div>
          </div>
          <div class="c-v2adv__item">
            <div class="c-adv__nmb u-gutter">/ 05</div>
            <div class="c-adv__label u-gutter">All-in-One Solution</div>
            <div class="o-nhg24 c-adv__txt">
              From user-research to scalable design systems we’ve got you
              covered
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
