import React from "react";
import Logo from "../Assets/Logo.svg";
import { Link } from "react-router-dom";
import "../css/nav.css";
import logo_outlined from '../Assets/Logo_outlined.svg'
import Small_logo from "../Assets/miniature-small.svg";


export default function Nav() {
  return (
    <div className="main-nav-2">
      <div className="navigation">
        <Link to="/">
          {/* <img className="logo" src={Logo}></img> */}
          <img className="logo2" src={logo_outlined}></img>
          <img className="logo-small2" src={Small_logo}></img>
        </Link>
        <div className="nav-content2">
          <Link className="work-nav-2" to="/about">
            About
          </Link>
          <Link className="work-nav2-2" to="/work">
            Work
          </Link>
          <div class="project-btn2">
            <a
              style={{  textDecoration: "none" }}
              href="mailto:hello@theminiature.club"
            >
              Start a project
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
