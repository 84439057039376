import React, { useEffect, useRef, useState } from "react";
import "../css/about2.css";

const PricingSection = () => {
  const sectionRef = useRef(null);
  const [isLeftFixed, setIsLeftFixed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const { top, bottom } = sectionRef.current.getBoundingClientRect();
        if (top <= 0 && bottom > window.innerHeight) {
          setIsLeftFixed(true);
        } else {
          setIsLeftFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section ref={sectionRef} className="pricing-section">
      <div className={`left-column ${isLeftFixed ? "sticky" : ""}`}>
        <h1 className="pricing-head">
          Pricing Model that suits your business needs
        </h1>
        <p className="pricing-para">
          We provide flexible pricing options, including hourly rates,
          fixed-price projects, and retainer agreements. Choose the model that
          aligns with your goals and budget for optimal value. Contact us for a
          personalized quote!
        </p>
        <div
          class="project-btn2"
          style={{
            width : "fit-content",
            padding : "10px 24px"
          }}
        >
          <a
            style={{ textDecoration: "none" }}
            href="mailto:hello@theminiature.club"
          >
            Start a Project
          </a>
        </div>
      </div>
      <div className="right-column">
        <h2>For New Startups:</h2>
        <div className="card">
          <span>
            <h3>Hourly Subscription</h3>
            <p>
              Ideal for startups that are just getting started and may have
              unpredictable needs. This model allows flexibility to address
              immediate design tasks without committing to a full project scope.
            </p>
          </span>
          <span>
            <p
              style={{
                color: "white",
                opacity: 0.7,
              }}
            >
              Benefits
            </p>
            <ul>
              <li>
                <span
                  style={{
                    color: "rgb(255 111 252)",
                  }}
                >
                  Flexibility:{" "}
                </span>
                Pay only for the hours you use.
              </li>
              <li>
                <span
                  style={{
                    color: "rgb(255 111 252)",
                  }}
                >
                  Immediate Support:
                </span>{" "}
                Access to design services as needed, perfect for launching your
                brand.
              </li>
            </ul>
          </span>
        </div>

        <h2>For Established Organizations or Mid-Level Startups</h2>
        <div className="card">
          <span>
            <h3>Fixed-Price Projects</h3>
            <p>
              Best for startups that have clearer goals and project scopes. This
              pricing model provides a set fee for defined deliverables,
              ensuring transparency and budget predictability.
            </p>
          </span>
          <span>
            <p
              style={{
                color: "white",
                opacity: 0.7,
              }}
            >
              Benefits
            </p>
            <ul>
              <li>
                <span
                  style={{
                    color: "rgb(255 111 252)",
                  }}
                >
                  Clear Expectations:{" "}
                </span>
                Know the total cost upfront with well-defined project scopes.
              </li>
              <li>
                <span
                  style={{
                    color: "rgb(255 111 252)",
                  }}
                >
                  Focused Deliverables:
                </span>{" "}
                Allows you to plan your budget and project timelines
                effectively.
              </li>
            </ul>
          </span>
        </div>
        <div className="card">
          <span>
            <h3>Retainer</h3>
            <p>
              A suitable option for mid-level startups looking for ongoing
              design support and strategic collaboration. This model offers a
              set number of hours per month or quarterly for a flat fee.
            </p>
          </span>
          <span>
            <p
              style={{
                color: "white",
                opacity: 0.7,
              }}
            >
              Benefits
            </p>
            <ul>
              <li>
                <span
                  style={{
                    color: "rgb(255 111 252)",
                  }}
                >
                  Consistent Support:{" "}
                </span>
                Regular access to design services ensures continuity in branding
                and marketing efforts.
              </li>
              <li>
                <span
                  style={{
                    color: "rgb(255 111 252)",
                  }}
                >
                  Cost-Effective:
                </span>{" "}
                Reduces overall costs compared to hourly rates for regular
                tasks.
              </li>
            </ul>
          </span>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
