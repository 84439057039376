import React from "react";

export default function GetInTouch() {
  return (
    <div className="GIT">
      <a
        className="getintouch"
        style={{ textDecoration: "none" }}
        href="mailto:people@theminiature.co"
      >
        <div className="div-reach">
          <p className="text-p">
          Want to start a project with us?
          </p>
          <p className="text-p"></p>
        </div>
        <h1 className="text-h">GET IN TOUCH <span class="arrow">→</span></h1>
        
      </a>
    </div>
  );
}
