import React, { useEffect, useState } from "react";
import Nav from "./nav";
import Footer from "./footer";
import Prismic from "@prismicio/client";
import "../css/work-detail.css";
import { useLocation } from "react-router-dom";
import Nav2 from "./nav_alt";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text?.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

export default function CaseStudy() {
  const apiEndpoint = "https://miniature.prismic.io/api/v2";
  const accessToken =
    "MC5ZZHh2dGhNQUFDWUFqbkU5.DAhf77-977-977-9KVY377-9cO-_ve-_ve-_ve-_vUoC77-9XBNDdgzvv71y77-977-9P3Pvv70u77-9";

  const client = Prismic.client(apiEndpoint, { accessToken });
  const [caseStudy, setCaseStudy] = useState([]);

  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
    const n = location.pathname.lastIndexOf("/");
    const result = location.pathname.substring(n + 1);
    getCaseStudyData(result);
  }, []);

  //Scroll Nav
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //accordion
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getCaseStudyData = async (event) => {
    console.log("event", event);
    const response = await client.getByUID("case_study", event);
    if (response) {
      setCaseStudy(response.data);
      console.log("casestudy", response.data);
    }
  };

  return (
    <div className="work-detail-main">
      <Nav2 />
      <div className={`navbar ${scrolled ? "scrolled" : ""}`}>
        <div className="cs-content">
          {scrolled ? (
            <>
              <div className="cs-top-scroll">
                <div className="cs-title-scroll">
                  <h1>{caseStudy.title}</h1>
                </div>
                <div className="cs-links-scroll">
                  <span className="links-scroll">
                    <a>Project Link</a>
                    <button
                      className={`accordion-button ${isOpen ? "open" : ""}`}
                      onClick={toggleAccordion}
                    >
                      Description
                    </button>
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="cs-top">
                <div className="cs-title">
                  <p>{caseStudy.services}</p>
                  <h1>{caseStudy.title}</h1>
                  <h2>{caseStudy.tagline}</h2>
                </div>
                <div className="cs-links">
                  <div className="logo-cs">
                    <img className="header-image" src={caseStudy.logo_link} />
                  </div>
                  <span className="links">
                    <a>Project Link</a>
                    <button
                      className={`accordion-button ${isOpen ? "open" : ""}`}
                      onClick={toggleAccordion}
                    >
                      Description
                    </button>
                  </span>
                </div>
              </div>
            </>
          )}

          <div className={`accordion-content ${isOpen ? "open" : ""}`}>
            {caseStudy.case_study_desc?.map((e) => (
              <div>
                <div className="content">
                  <h1 className="heading">{e.heading}</h1>
                  <p className="description">{e.desc}</p>
                </div>
              </div>
            ))}

            {/* Content goes here */}
            {/* <div className="content">
              <h1 className="heading">
                {caseStudy.case_study_desc[0].heading_1}
              </h1>
              <p className="description">
                {caseStudy.case_study_desc[0].desc_1}
              </p>
            </div>
            <div className="content">
              <h1 className="heading">
                {caseStudy.case_study_desc[0].heading_2}
              </h1>
              <p className="description">
                {caseStudy.case_study_desc[0].desc_2}
              </p>
            </div>
            <div className="content">
              <h1 className="heading">
                {caseStudy.case_study_desc[0].heading_3}
              </h1>
              <p className="description">
                {caseStudy.case_study_desc[0].desc_3}
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <div className="work-detail-all">
        <div className="work-detail">
          <div>
            {caseStudy.objective1?.map((e) => (
              <div>
                {e.content_type == "yes" ? (
                  <ReadMore>{e.objective}</ReadMore>
                ) : (
                  <div>""</div>
                )}
              </div>
            ))}
          </div>

          <div className="work-detail-image">
            <img className="header-image" src={caseStudy.header_image} />
          </div>

          {caseStudy.images?.map((e) => (
            <div>
              {e.content_type == "Image" ? (
                <div className="work-detail-image">
                  <img className="case-study-images" src={e.image_link} />
                </div>
              ) : (
                <div className="work-detail-image">
                  <video
                    className="case-study-images"
                    src={e.video_link}
                    autoPlay
                    loop="loop"
                  ></video>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

{
  /* <div className="title-sec">
          <div className="title-tagline">
            <h1 className="title">{caseStudy.title}</h1>
            <p className="tagline ">{caseStudy.tagline}</p>
          </div>
          <div className="sub-details">
            <div className="client">
              <h3>Client</h3>
              <h1>{caseStudy.client_name}</h1>
            </div>
            <div className="services">
              <h3>Services</h3>
              <h1>{caseStudy.services}</h1>
            </div>
          </div>
        </div> */
}
