import React, { useEffect, useState } from "react";
import Nav2 from "./nav_alt";
import Footer from "./footer";
import Prismic from "@prismicio/client";
import { Link } from "react-router-dom";
import "../css/work-main.css";
import GetInTouch from "./getintouch";

export default function WorkMain() {
  const apiEndpoint = "https://miniature.prismic.io/api/v2";
  const accessToken =
    "MC5ZZHh2dGhNQUFDWUFqbkU5.DAhf77-977-977-9KVY377-9cO-_ve-_ve-_ve-_vUoC77-9XBNDdgzvv71y77-977-9P3Pvv70u77-9";

  const client = Prismic.client(apiEndpoint, { accessToken });
  const [doc, setDoc] = useState([]);
  const [caseStudy, setCaseStudy] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await client.query(
      Prismic.Predicates.at("document.type", "work"),
      { orderings: "[my.work.order desc]" }
    );
    if (response) {
      setDoc(response.results);
      console.log("harshit", response.results);
    }
  };
  const getCaseStudyData = async (event) => {
    console.log("event", event);
    const response = await client.getByUID("case_study", event.target.id);
    if (response) {
      setCaseStudy(response.data);
      console.log("casestudy", response.data);
    }
  };

  return (
    <div>
      <Nav2 />

      <div className="work-main-div">
        {doc?.map((e) => (
          <div className="work">
            <Link to={`/work-detail/${e.data.content_relationship.uid}`}>
              <div className="work-image">
                {e.content_type == "Image" ? (
                  <img
                    className="thumbnail"
                    src={e.data.images.image_link}
                  />
                ) : (
                  <video
                    className="thumbnail"
                    src={e.data.images.video_link}
                    muted
                    playsInline
                    loop="loop"
                    autoPlay="autoplay"
                  ></video>
                )}
              </div>
            </Link>

            <div className="work-name-btn">
              <p>{e.data.description}</p>
              <Link
                to={`/work-detail/${e.data.content_relationship.uid}`}
                id={e.data.content_relationship.uid}
                className="case-study-btn btn-2"
                onClick={(e) => {
                  getCaseStudyData(e);
                }}
              >
                Read Case Study →
              </Link>
              {/* <a class="mailto" href="mailto:harshitsharma34@gmail.com">Mail</a> */}
            </div>
          </div>
        ))}
      </div>
      <GetInTouch />

      <Footer />
    </div>
  );
}
