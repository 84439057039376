import Miniature from "./Component/miniature";
import WorkMain from "./Component/work-main";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import CaseStudy from "./Component/caseStudy";
import About from "./Component/about";
import Test from './Component/work_test'



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Miniature />} />
          <Route path="work" element={<WorkMain />} />
          <Route path="about" element={<About/>} />
          <Route path="work_test" element={<Test/>} />
          <Route path="work-detail/:id" element={<CaseStudy/>} />
          <Route path="work-detail/:id" element={<CaseStudy/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
