import React, { useState, useRef, useEffect } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "../css/about2.css";

const faqs = [
  {
    question: "What design services do you offer?",
    answer: (
      <div className="faq-ans">
        We offer a comprehensive range of design services, including: -
        <br />
        <br />
        <span className="faq-ans-head">UI/UX Design:</span> Creating intuitive
        and engaging user interfaces for websites, mobile apps, and software. -
        <br />
        <br />
        <span className="faq-ans-head">Web Design:</span> Designing responsive
        websites with a focus on aesthetics and functionality. -
        <br />
        <br />
        <span className="faq-ans-head">Branding:</span> From logo creation to
        complete brand identity, ensuring your brand stands out. -
        <br />
        <br />
        <span className="faq-ans-head">Product Design:</span> End-to-end product
        design, including wireframes, prototyping, and final designs. -
        <br />
        <br />
        <span className="faq-ans-head">Mobile App Design:</span> Delivering
        seamless app designs that enhance user experience.
      </div>
    ),
  },
  {
    question: "How do you ensure good communication during the project?",
    answer:
      " We understand the importance of clear communication, especially for international clients. We schedule regular check-ins through video calls, and use project management platforms like Asana, Trello, or Basecamp to keep all stakeholders updated. We are also flexible with time zones and adapt to your preferred communication methods",
  },
  {
    question: "What is your design process?",
    answer: (
      <div className="faq-ans">
        We follow a structured, research-driven process to deliver high-quality
        results:
        <br />
        <br />
        <span className="faq-ans-head">Discovery Phase:</span> Understanding
        your business, target audience, and project goals.-
        <br />
        <br />
        <span className="faq-ans-head">Research & Strategy:</span> Conducting
        market research and developing design strategies based on industry
        insights.-
        <br />
        <br />
        <span className="faq-ans-head">Wireframing & Prototyping: </span>
        Creating wireframes and prototypes to define the layout and flow. -
        <br />
        <br />
        <span className="faq-ans-head">Design & Iteration:</span> We present the
        first draft, gather feedback, and refine the design. -
        <br />
        <br />
        <span className="faq-ans-head">Testing & Handover:</span> Ensuring the
        design functions across devices, we hand over the final deliverables for
        development.
      </div>
    ),
  },
  {
    question: "Can you work with clients in different time zones?",
    answer:
      "Our team is well-versed in remote collaboration. We use cloud-based platforms to manage all aspects of the project and maintain transparency. Whether you’re in a different time zone or continent, we ensure seamless project management and timely delivery.",
  },
  {
    question: "What industries do you specialize in?",
    answer: (
      <div className="faq-ans">
        We have extensive experience in a variety of industries, including:
        <br />
        <br />
        <div className="faq-chips">
          <span
            className="faq-ans-head"
            style={{
              border: "1px solid #FB00F6",
              padding: "6px 20px 6px",
              backgroundColor: "#fb00f60d",
              borderRadius: "50px",
              color: "white",
            }}
          >
            AI
          </span>{" "}
          &nbsp;
          <span
            className="faq-ans-head"
            style={{
              border: "1px solid #FB00F6",
              padding: "6px 20px 6px",
              backgroundColor: "#fb00f60d",
              borderRadius: "50px",
              color: "white",
            }}
          >
            E-commerce
          </span>{" "}
          &nbsp;
          <span
            className="faq-ans-head"
            style={{
              border: "1px solid #FB00F6",
              padding: "6px 20px 6px",
              backgroundColor: "#fb00f60d",
              borderRadius: "50px",
              color: "white",
            }}
          >
            Fintech
          </span>{" "}
          &nbsp;
          <span
            className="faq-ans-head"
            style={{
              border: "1px solid #FB00F6",
              padding: "6px 20px 6px",
              backgroundColor: "#fb00f60d",
              borderRadius: "50px",
              color: "white",
            }}
          >
            Healthcare
          </span>{" "}
          &nbsp;
          <span
            className="faq-ans-head"
            style={{
              border: "1px solid #FB00F6",
              padding: "6px 20px 6px",
              backgroundColor: "#fb00f60d",
              borderRadius: "50px",
              color: "white",
            }}
          >
            Web3
          </span>{" "}
          &nbsp;
          <span
            className="faq-ans-head"
            style={{
              border: "1px solid #FB00F6",
              padding: "6px 20px 6px",
              backgroundColor: "#fb00f60d",
              borderRadius: "50px",
              color: "white",
            }}
          >
            SaaS
          </span>{" "}
          &nbsp;
          <span
            className="faq-ans-head"
            style={{
              color: "#fb00f6",
            }}
          >
            + more
          </span>{" "}
          <br />
          <br />
        </div>
        Our experience allows us to craft tailored solutions that resonate with
        each sector’s specific needs.
      </div>
    ),
  },
  {
    question: "How long does a typical design project take?",
    answer: (
      <div className="faq-ans">
        Timelines vary based on the scope and complexity of the project. A
        simple website design may take{" "}
        <span
          className="faq-ans-head"
          style={{
            color: "#fb00f6",
          }}
        >
          2-3 weeks
        </span>
        , while a more extensive mobile app design could take{" "}
        <span
          className="faq-ans-head"
          style={{
            color: "#fb00f6",
          }}
        >
          1-2 months
        </span>
        . After an initial consultation, we provide a detailed timeline tailored
        to your specific project requirements.
      </div>
    ),
  },
  {
    question: "Do you work with startups?",
    answer:
      "Absolutely! We love collaborating with startups to bring their ideas to life. From creating an MVP (Minimum Viable Product) to scaling up the product design as your business grows, we’re experienced in supporting early-stage companies.",
  },
  {
    question: "What makes your design studio different?",
    answer: (
      <div className="faq-ans">
        Our studio is focused on: -
        <br />
        <br />
        <span className="faq-ans-head">Client-Centric Design:</span> We
        prioritize your vision, needs, and user base.
        <br />
        <br />
        <span className="faq-ans-head">Tailored Solutions:</span> We don’t
        believe in one-size-fits-all. Each design is crafted to solve specific
        business challenges.
        <br />
        <br />
        <span className="faq-ans-head">Global Perspective:</span> Our experience
        with international clients allows us to design for a variety of cultural
        contexts.
      </div>
    ),
  },

  {
    question: "How do you ensure the confidentiality of my project?",
    answer:
      "   We take confidentiality seriously and are willing to sign Non-Disclosure Agreements (NDAs) to protect your sensitive information. Our team follows best practices to ensure data security throughout the project lifecycle.",
  },
  // Add more FAQs as needed
];

const FAQItem = ({ question, answer, isOpen, toggle }) => (
  <div className="faq-item" onClick={toggle} style={{ cursor: "pointer" }}>
    <div className="faq-sub">
      <h3 className="faq-question">{question}</h3>
      <div
        class="w-fit h-fit border rounded-full p-2 toggle-icon"
        onClick={toggle}
        style={{ cursor: "pointer" }}
      >
        <svg
          className={`icon ${isOpen ? "rotate" : ""}`}
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <rect x="4" y="11" width="16" height="2" rx="1" />
          <rect
            className={`vertical ${isOpen ? "hidden" : "visible"}`}
            x="11"
            y="4"
            width="2"
            height="16"
            rx="1"
          />
        </svg>
      </div>
    </div>
    <p className={`faq-answer ${isOpen ? "faq-open" : ""}`}>{answer}</p>
  </div>
);
export default function Testimonial() {
  const sectionRef = useRef(null);
  const [isLeftFixed, setIsLeftFixed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const { top, bottom } = sectionRef.current.getBoundingClientRect();
        if (top <= 0 && bottom > window.innerHeight) {
          setIsLeftFixed(true);
        } else {
          setIsLeftFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <div>
        <h1 className="test-head">What our clients say about us</h1>
        <div className="testimonial-div">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              // when window width is >= 640px

              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
          >
            <SwiperSlide>
              <div className="testimonial 1">
                <p className="test-body">
                  Working with Miniature was instrumental in boosting our image.
                  They captured the essence of 'Empowering Your Rapid Growth
                  with Chat Commerce' perfectly, creating a website that's both
                  informative and visually appealing. We've seen a significant
                  increase in user engagement and a clearer understanding of our
                  value proposition.
                </p>
                <div style={{ paddingBottom: "30px" }}>
                  <p className="author-name">Wing Yuen</p>
                  <p className="company">Rum Work, Co-founder</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial 2">
                <p className="test-body">
                  Miniature completely grasped our vision for WOO, the fintech
                  app we envisioned. Their design is not only stunning but also
                  functional, making it a breeze to manage finances. The
                  Miniature team was responsive, collaborative, and a pleasure
                  to work with. I highly recommend them for any fintech design
                  project.
                </p>
                <div style={{ paddingBottom: "30px" }}>
                  <p className="author-name">Rory MacQueen</p>
                  <p className="company">Woo, Co-founder</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial 3">
                <p className="test-body">
                  We've gone from financial chaos to clarity, with real-time
                  visibility, effortless automation, and data-driven decision
                  making that fuels our growth. I highly recommend Miniature to
                  any company seeking a powerful and user-friendly financial
                  solution.
                </p>
                <div style={{ paddingBottom: "30px" }}>
                  <p className="author-name">Rohit Agarwal</p>
                  <p className="company">Product lead</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <section
          ref={sectionRef}
          className="pricing-section"
          style={{ marginTop: "calc(6vw + 6vh + 6vmin)" }}
        >
          <div className={`left-column ${isLeftFixed ? "sticky" : ""}`}>
            <h2 className="faq-title">Frequently Asked Questions</h2>
          </div>
          <div className="right-column">
            <div className="faq-container">
              <div className="faqs">
                {faqs.map((faq, index) => (
                  <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    isOpen={openIndex === index}
                    toggle={() => toggleFAQ(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
