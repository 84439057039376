import React, { useEffect, useState } from "react";
import "../css/work.css";
import Prismic from "@prismicio/client";
import { Link } from "react-router-dom";
import arrow from "../Assets/arrow_work.svg";
import calender from "../Assets/calendar.svg";

export default function Work() {
  const apiEndpoint = "https://miniature.prismic.io/api/v2";
  const accessToken =
    "MC5ZZHh2dGhNQUFDWUFqbkU5.DAhf77-977-977-9KVY377-9cO-_ve-_ve-_ve-_vUoC77-9XBNDdgzvv71y77-977-9P3Pvv70u77-9";

  const client = Prismic.client(apiEndpoint, { accessToken });
  const [doc, setDoc] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await client.query(
      Prismic.Predicates.at("document.type", "work")
    );
    if (response) {
      setDoc(response.results);
      console.log("response", response.results);
    }
  };

  return (
    <div className="work-main">
      <div className="head-work">
        <div>
          <h2 className="work-head">Selected Work</h2>

          <p>
            Our portfolio encompasses a wide range of digital designs essential
            for the growth of modern businesses. From B2B SaaS and B2C mobile
            apps to marketing design for promotions, we display our work created
            for early-stage startups and enterprises at various stages of their
            growth.
          </p>
        </div>
        <img src={arrow} />
      </div>

      {/* <h1>selected work</h1> */}
      {/* work 1 */}
      <div className="parent-work">
        <div className="work-description">
          <div className="cs-title">
            <h1>{doc[0]?.data.title}</h1>
            <p>{doc[0]?.data.description}</p>

            <div className="tags-cs">
              {doc[0]?.data.tags.map((e) => (
                <button className={`tag`}>{e.tag}</button>
              ))}
            </div>
          </div>
          <Link
            to={`/work-detail/${doc[0]?.data.content_relationship.uid}`}
            className="cta-cs"
          >
            Read Case Study <span>→</span>
          </Link>
        </div>

        <div className="work-thumbnail">
          <video
            className="thumbnail"
            src={doc[0]?.data.thumbnail}
            muted
            playsInline
            loop="loop"
            autoPlay="autoplay"
          ></video>
        </div>
        {/* Work 2 */}
      </div>
      <div className="parent-work">
        <div className="work-description">
          <div className="cs-title">
            <h1>{doc[1]?.data.title}</h1>
            <p>{doc[1]?.data.description}</p>

            <div className="tags-cs">
              {doc[1]?.data.tags.map((e) => (
                <button className={`tag`}>{e.tag}</button>
              ))}
            </div>
          </div>
          <Link
            to={`/work-detail/${doc[1]?.data.content_relationship.uid}`}
            className="cta-cs"
          >
            Read Case Study <span>→</span>
          </Link>
        </div>

        <div className="work-thumbnail">
          <video
            className="thumbnail"
            src={doc[1]?.data.thumbnail}
            muted
            playsInline
            loop="loop"
            autoPlay="autoplay"
          ></video>
        </div>
      </div>
      {/* Work 3 */}
      <div className="parent-work">
        <div className="work-description">
          <div className="cs-title">
            <h1>{doc[2]?.data.title}</h1>
            <p>{doc[2]?.data.description}</p>

            <div className="tags-cs">
              {doc[2]?.data.tags.map((e) => (
                <button className={`tag`}>{e.tag}</button>
              ))}
            </div>
          </div>
          <Link
            to={`/work-detail/${doc[2]?.data.content_relationship.uid}`}
            className="cta-cs"
          >
            Read Case Study <span>→</span>
          </Link>
        </div>

        <div className="work-thumbnail">
          <video
            className="thumbnail"
            src={doc[2]?.data.thumbnail}
            muted
            playsInline
            loop="loop"
            autoPlay="autoplay"
          ></video>
        </div>
      </div>
      <div
       className="view-all"
      >
        <h1>Peeked your Interest?</h1>
        <div
          class="project-btn3"
          style={{
            width: "fit-content",
            height :"fit-content",
           
          }}
        >
          <a
            style={{ textDecoration: "none" }}
            href="mailto:hello@theminiature.club"
            color="black"
          >
            View All Work
          </a>
        </div>
      </div>
      <div className="start-day">
        <div className="head-work">
          <div>
            <h2 className="work-head" style={{ marginBlockEnd: "0em" }}>
              Start within a Day!
            </h2>

            <h2
              className="work-head"
              style={{ marginBlockEnd: "0em", marginBlockStart: "0em" }}
            >
              How it Works
            </h2>
          </div>
          <img src={calender} />
        </div>

        <div className="howitworks-main">
          <div className="howitworks-sub">
            <div className="howitworks1">
              <h2 className="book-min">
                <h3>01.</h3>Book Your Free 15-Min Design Audit Call
              </h2>
              <p>
                Nice to meet you. We'll discuss your design and business needs
                together. It's a chance for you to ask questions and ensure
                we're a good fit before moving forward.
              </p>
            </div>
            <Link
              className="project-btn2"
              style={{ textDecoration: "none", marginLeft: "50px" }}
              href="mailto:hello@theminiature.club"
              to="/work"
            >
              Book a Call
            </Link>
          </div>

          <div className="howitworks">
            <h2 className="book-min">
              <h3>02.</h3>Start Your Subscription
            </h2>
            <p>
              If our service is a good match, start your subscription for
              ongoing access to our design services. Choose update frequency
              based on your needs and budget.
            </p>
          </div>

          <div className="howitworks">
            <h2 className="book-min">
              <h3>03.</h3>Design Kick Off
            </h2>
            <p>
              Next we discuss your design requirements in depth. Branding,
              audience, and business goals to make sure the final product aligns
              with your vision and objectives.
            </p>
          </div>
          <div className="howitworks">
            <h2 className="book-min">
              <h3>04.</h3>To the Moon 🚀
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
