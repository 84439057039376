import React, { useEffect, useState } from 'react';
import '../css/RevealLoader.css';

const RevealLoader = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timer as needed
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={`reveal-loader ${loading ? "visible" : "hidden"}`}>
      <div className="loader-content">
        <h1>Loading...</h1>
      </div>
    </div>
  );
};
export default RevealLoader;
