import React from "react";
import blur from "../Assets/blur.png";
export default function Approach() {
  return (
    <div className="approach">
      <span className="huge-impact">
        <span>Small Changes that drive</span>
        <h1 className="small_changes" style={{ color: "#Ffffff" }}>
          Huge Impact
        </h1>
        <div class="project-btn2" style={{ width: "120px", marginTop: "60px" }}>
          <a
            style={{ textDecoration: "none" }}
            href="mailto:hello@theminiature.club"
          >
            Start a Project
          </a>
        </div>
      </span>

      <div>
        <p>
          What sets us apart is that the work we do with clients is long-term in
          nature and allows us to foster a connection with the people we work
          with. We work closely with our clients’ internal teams and slot
          seamlessly into their day-to-day operations. This philosophy blends
          seamlessly with a focus on supporting an intentionally select group of
          high- end clients, which in turn allows us to offer maximum impact in
          reaching their goals. Our projects often involve helping businesses go
          to market, but we also work as specialists on tactical engagements.
        </p>
        <h1 className="strategy">Strategy → Design → Integration</h1>
        <br />
        <p>
          Craft, execute and smile. We are a digital agency. Our approach is
          characterized by an agile method combining strategy, design and
          integration. Oh, and great people too.
        </p>
      </div>
    </div>
  );
}
