import React, { useState } from "react";
import { Scrollama, Step } from "react-scrollama";

const ScrollamaDemo = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(null);

  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };

  return (
    <div className="scrolly-div">
      <Scrollama offset={0.5} onStepEnter={onStepEnter} >
        <Step data={1}>
          <div
            style={{
              marginTop: "20vh",
              marginBottom: "20vh",
              width:"80%",
              color: "white",
              fontSize: currentStepIndex === 1 ? "calc(4rem + 3vw)" : "calc(2.5rem + 2.5vw)",
              transition: "font-size 0.5s ease, opacity 0.5s ease",
              opacity: currentStepIndex === 1 ? 1 : 0.05,
             
            }}
          >
            Ability to adapt to an <span>ever-changing world</span>
          </div>
        </Step>
        <Step data={2}>
          <div
            style={{
              margin: "20vh 20vh",
              color: "white",
              fontSize : currentStepIndex === 2 ? "calc(4rem + 3vw)" : "calc(2.5rem + 2.5vw)",
             
              transition: "font-size 0.5s ease, opacity 0.5s ease",
              opacity: currentStepIndex === 2 ? 1 : 0.05,
            }}
          >
            Design experiences that enable <span>people to feel engaged</span>
          </div>
        </Step>
        <Step data={3}>
          <div
            style={{
              margin: "20vh 20vh",
              color: "white",
              fontSize : currentStepIndex === 3 ? "calc(4rem + 3vw)" : "calc(2.5rem + 2.5vw)",
              transition: "font-size 0.5s ease, opacity 0.5s ease",
              opacity: currentStepIndex === 3 ? 1 : 0.05,
            }}
          >
            Stay <span>relevant</span>
          </div>
        </Step>
        <Step data={4}>
          <div
            style={{
              margin: "20vh 20vh",
              color: "white",
              fontSize : currentStepIndex === 4 ? "calc(4rem + 3vw)" : "calc(2.5rem + 2.5vw)",
              transition: "font-size 0.5s ease, opacity 0.5s ease",
              opacity: currentStepIndex === 4 ? 1 : 0.05,
            }}
          >
            True craft.<span> No bullshit.</span>
          </div>
        </Step>
        <Step data={5}>
          <div
            style={{
              margin: "20vh 20vh",
              color: "white",
              fontSize : currentStepIndex === 5 ? "calc(4rem + 3vw)" : "calc(2.5rem + 2.5vw)",
              transition: "font-size 0.5s ease, opacity 0.5s ease",
              opacity: currentStepIndex === 5 ? 1 : 0.05,
              
            }}
          >
            Content is the <span>king</span>
          </div>
        </Step>
        <Step data={6}>
          <div
            style={{
              margin: "20vh 20vh",
              color: "white",
              fontSize : currentStepIndex === 6 ? "calc(4rem + 3vw)" : "calc(2.5rem + 2.5vw)",
              transition: "font-size 0.5s ease, opacity 0.5s ease",
              opacity: currentStepIndex === 6 ? 1 : 0.05,
            }}
          >
            Keeping small team to ensure <span>high level of care</span>
          </div>
        </Step>
      </Scrollama>
    </div>
  );
};

export default ScrollamaDemo;
